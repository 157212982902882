import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Badge from "components/Badge/Badge";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { CoordinatorsForm } from "./Coordinator/index";
import { ParticipantsForm } from "./Participants/index";
import Audit from "./Audits/index";
import Transactions from "./Transactions/index";
import Danger from "components/Typography/Danger";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { colors } from "@material-ui/core";
import { SummaryForm } from "./Summary";
import { I18n } from "PNH/components/I18n";
import PageViewer from "PNH/views/Pages/Viewer";
import CustomTabs from "components/CustomTabs/CustomTabs";
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import PeopleIcon from "@material-ui/icons/People";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Api } from "PNH/services/Api";
import {FiltersForm} from "../../BenefitEntries/Search";
import PaymentIcon from "@material-ui/icons/Payment";
import {TeamsTab} from "./Teams";

const ShowActivity = ({activityID, ...props}) => {
  const [activity, setActivity] = useState({})

  useEffect(() => {
      Api.activities.admin.show(activityID).then((response) => {
        if (response.ok) {
          setActivity(response.data)
        } else {
          this.props.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    
  }, [])

  if(!activity.id) {
    return null;
  }

  return (
    <div>
      <h3>
        Zajęcia: #{activity.id} - {I18n.t(activity.activity_type)}{" "}
        {activity.canceled && (
          <Danger>
            {" "}
            <b>Odwołane</b>
          </Danger>
        )}
      </h3>
      <b>Data:</b>{activity.date}, <b> Godzina: </b> {activity.start_at}-{activity.stop_at}, <b>Lokalizacjia: </b> {activity.localization.label}
      <br />
      {activity.hidden && (<>
        <b>Link:</b> <a href={`${process.env.REACT_APP_MAIN_PAGE_URL}${activity.hidden_url}`} target="_blank">{process.env.REACT_APP_MAIN_PAGE_URL}{activity.hidden_url}</a>

      </>
      )}
      {!activity.hidden && (<>
        <b>Link:</b> <a href={`${process.env.REACT_APP_MAIN_PAGE_URL}${activity.url}`} target="_blank">{process.env.REACT_APP_MAIN_PAGE_URL}{activity.url}</a>
      </>
      )}

      <Card>
        <CardBody>
          <GridContainer justify="space-between">
            <GridItem>
              <h5>
                <b>Ogólne</b>
              </h5>
              <hr />
              <p>
                <b>Cena:</b> {activity.price}
                <br />
                <b>Min. ilość graczy:</b> {activity.min_players}
                <br />
                <b>Max. ilość graczy:</b> {activity.max_players}
                <br />
                <b>Miejsca zarezerwowane:</b> {activity.reserved_spots}
                <br />
                <b>Zajęte miejsca: </b> {activity.all_reserved_spots}
                <br />
                <b>Nr Medicover Sport: </b> {activity.ok_system_code}
                <br />
                <b>Dopłata Medicover Sport: </b> {activity.supplement_medicover}
                <br />
                <b>Dopłata Multi Sport: </b> {activity.supplement}
                <br />
                <b>Dopłata FitProfit: </b> {activity.supplement_fitprofit}
                <br />
                <b>Koszt wynajmu: </b> {activity.rental_price}
                <br />
              </p>
            </GridItem>

            <GridItem>
              <h5>
                <b>Opcje</b>
              </h5>
              <hr />

              <b>Weryfikacja poziomu:</b> {activity.verify_level ? "tak" : "nie"}
              <br />
              <b>Trener:</b> {activity.coach ? "tak" : "nie"}
              <br />
              <b>Kaucja wyłączona:</b> {activity.deposit_disabled ? "tak" : "nie"}
              <br />
            </GridItem>
            <GridItem md={3}>
              <h5>
                <b>Tagi filtrujące</b>
              </h5>
              <hr />
              {activity.tags &&
                activity.tags.split("#").map((tag) => {
                  return (
                    <Badge color="gray" key={tag}>
                      {tag}
                    </Badge>
                  );
                })}
            </GridItem>
            <GridItem xs={4}>
              <h5>
                <b>Dodane przez:</b> {I18n.t(activity.event_type)}
              </h5>
              <hr />

              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Link to={`/users/${activity.creator_id}`} >
                        <GridContainer alignItems="center" >
                          <GridItem xs={1}>
                            <PeopleIcon style={{ color: '#1ad1a2' }} />
                          </GridItem>
                          <GridItem xs={11} style={{ color: 'black' }}>
                            <h5 ><b>{activity.creator.label}</b></h5>
                            Dodano: {activity.created_at}
                          </GridItem>
                        </GridContainer>
                      </Link>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              {organizatorNotVerified(activity)}
              {organizatorVerified(activity)}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CoordinatorsForm activityID={activity.id} activity={activity} {...props} />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <ParticipantsForm activityID={activity.id} activity={activity} {...props} />
        </CardBody>
      </Card>
      <SummaryForm activityID={activity.id} {...props} />

      <div style={{ marginTop: "50px" }}></div>
      <CustomTabs

        headerColor="primary"
        tabs={[
          {
            tabName: <>Lista rezerwowych</>, tabIcon: SportsBasketballIcon, tabContent: <ul>
              {activity.reserve_list.map((u) => {
                return <li key={u.id}> {u.label} </li>;
              })}
            </ul>
          },
          {
            tabName: <>Opis</>, tabIcon: SportsBasketballIcon, tabContent: activity.page_id && activity.page && <PageViewer id={activity.page_id} pageBody={activity.page.body} />
          },
          {
            tabName: <>Transakcje</>, tabIcon: SportsBasketballIcon, tabContent: <Transactions activityID={activity.id} {...props} />
          },
            {
                tabName: <>Odbicia Benefit</>,
                tabIcon: PaymentIcon,
                tabContent: <FiltersForm activityID={activity.id} {...props} />
            },
          {
            tabName: <>Historia zmian</>, tabIcon: SportsBasketballIcon, tabContent: <Audit activityID={activity.id} {...props} />
          },
        ]}
      />

    </div>
  );
}


const list = (activity) => {
  if (!activity.id) {
    return null;
  }
  let list = Array(activity.reserved_spots).fill(
    "Miejsce Zarezerwowane"
  );
  let counter = 1;
  return (
    <ul style={{ listStyleType: "decimal" }}>
      {list.map((r) => {
        return <li>{r}</li>;
      })}
      {activity.user_activity_participants.map((r) => {
        return <li>{r.label}</li>;
      })}
    </ul>
  );
};

const organizatorVerified = (activity) => {
  if (activity.creator.signed_agreement === 'yes' &&
    activity.creator.transfer_verification === 'yes' &&
    _.find(
      activity.creator.user_agreements_attributes,
      {
        sport_id: activity.sport_id,
        localization_id: activity.localization_id,
        event_length: activity.event_length,
        event_price: activity.price,
        event_type: activity.event_type
      })) {
    return (
      <Card>
        <CardBody>
          <h4 style={{ color: colors.green["700"], fontWeight: 'bold' }}>
            Organizator zweryfikowany
          </h4>
        </CardBody>
      </Card>
    )
  }
};

const organizatorNotVerified = (activity) => {
  if (activity.creator.signed_agreement === 'no' || activity.creator.transfer_verification === 'no') {
    return (
      <Card>
        <CardBody>
          <h4 style={{ color: colors.red["700"], fontWeight: 'bold' }}>
            Organizator niezweryfikowany
          </h4>
        </CardBody>
      </Card>
    )
  }
};



export default withStyles(dashboardStyle)(ShowActivity);
